import React from "react"
import { Flex } from "reflexbox"
import styled from "../../config"
import { Link } from "gatsby"

import Blob from "../../components/Blob"

const AfspraakMaken = () => (
  <Four04Wrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Pagina niet gevonden</h1>
    </Flex>
    <Flex mt={5}>
      <p>
        Klik <StyledLink to="/">hier</StyledLink> om terug te keren naar de
        startpagina.
      </p>
    </Flex>
  </Four04Wrapper>
)

export default AfspraakMaken

const Four04Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
`
