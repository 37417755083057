import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import FourO4 from "../modules/Four04/Four04.jsx"

const Four04PAge = () => (
  <GlobalCollection>
    <FourO4 />
  </GlobalCollection>
)

export default Four04PAge
